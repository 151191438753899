<template>
  <div>
    <b-card>
      <b-row>
        <!-- Order ID -->
        <b-col>
          <b-form-group label="Order ID" label-for="Input1">
            <b-form-input
              v-model="form.search.order_id"
              placeholder="Enter Order ID"
              autocomplete="off"
              @keyup.enter="Search()"
            />
          </b-form-group>
        </b-col>
        <!-- อุปกรณ์ที่เช่า -->
        <b-col>
          <b-form-group label="อุปกรณ์" label-for="Input2">
            <b-form-input
              v-model="form.search.equipment_name"
              placeholder="Enter Equipment"
              autocomplete="off"
              @keyup.enter="Search()"
            />
          </b-form-group>
        </b-col>
        <!-- ข้อมูลลูกค้า -->
        <b-col>
          <b-form-group label="ข้อมูลลูกค้า" label-for="Input3">
            <b-form-input
              v-model="form.search.customer_info"
              placeholder="Enter Customer Info"
              autocomplete="off"
              @keyup.enter="Search()"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span class="font-weight-bold">Order ถูกลบ</span>
          <b-form-checkbox
            class="custom-control-success"
            name="check-button"
            switch
            v-model="form.is_delete"
          />
        </b-col>
      </b-row>
      <b-row class="text-right">
        <b-col>
          <b-button
            v-if="
              form.search.customer_info ||
              form.search.equipment_name ||
              form.search.order_id
            "
            variant="secondary"
            class="mr-2"
            @click="clear"
            >Clear</b-button
          >
          <!-- <b-button variant="success" @click="Search"></b-button> -->
          <b-button v-if="!loading" variant="relief-success" @click="Search()">
            Search
          </b-button>
          <b-button
            variant="relief-success"
            disabled
            class="mr-1"
            v-if="loading"
          >
            <b-spinner small /> &nbsp; Loading...
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { debounce } from "lodash";
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import { props } from "vue2-dropzone";

export default {
  components: {
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormCheckbox,
  },
  props: {
    selected_page: {
      type: Number,
      required: true,
    },
    per_page: {
      type: Number,
      default: 100, // Default value for max_limit
    },
  },
  computed: {},
  watch: {
    selected_page(newPage, oldPage) {
      // Handle changes to the selected_page prop here
      this.form.selected_page = newPage;
      this.Search();
    },
  },
  created() {
    this.Search();
  },
  data() {
    return {
      filter_data: [],
      form: {
        side: "all", /// รับ หรือ คืน
        search: {
          order_id: null,
          equipment_name: null,
          customer_info: null,
        },
        is_delete: false,
      },
      loading: false,
    };
  },
  methods: {
    Search() {
      this.form.selected_page = this.selected_page;
      this.form.per_page = this.per_page;
      this.loading = true;

      this.$http({
        method: "POST",
        url: `/order/list/filter`,
        data: this.form,
      })
        .then((x) => {
          this.filter_data = x.data.data;
          this.$emit("filter_data", {
            filter_data: x.data.data,
          });
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    clear() {
      this.loading = false;
      this.form.search.order_id = null;
      this.form.search.equipment_name = null;
      this.form.search.customer_info = null;
      this.Search();
    },
  },
};
</script>

<style></style>
