<template>
  <div>
    <!-- data มาจาก filter searcbar -->
    <filter-search-bar
      @filter_data="filtered_data"
      :selected_page="selected_page"
      :per_page="per_page"
    >
    </filter-search-bar>
    <b-card>
      <filter-table :filter_data="filter_data" />
      <order-pagination
        :total_rows="total_rows"
        :per_page="per_page"
        @pageChange="
          (x) => {
            this.selected_page = x;
          }
        "
      >
      </order-pagination>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import FilterTable from "../order/component/FilterTable.vue";
import FilterSearchBar from "../order/component/FilterSearchBar.vue";
import OrderPagination from "../order/component/OrderPagination.vue";

export default {
  components: {
    OrderPagination,
    BCard,
    BCardText,
    FilterTable,
    FilterSearchBar,
  },
  created() {},
  data() {
    return {
      per_page: 100,
      selected_page: 1,
      total_rows: 0,
      filter_data: [],
      form: {
        side: "all", /// รับ หรือ คืน
        search: {
          order_id: null,
          equipment_name: null,
          customer_info: null,
        },
      },
    };
  },
  methods: {
    filtered_data(x) {
      this.filter_data = x.filter_data;
      this.total_rows =
        this.filter_data.length > 0 ? this.filter_data[0].total_count : 0;
    },
  },
};
</script>

<style></style>
